.qr-container {
  width: 400px;
  height: 400px;
  border-radius: 200px;
  background-color: white;
}

.number {
  color: var(--Dark-100, #292d32);
  font-feature-settings: 'liga' off, 'clig' off;

  font-family: 'GeneralSans';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.title {
  color: var(--White-100, #fff);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'GeneralSans';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.title.completed {
  color: #292d3233;
  text-decoration-line: line-through;
}

.title.dark {
  color: #292d32;
}

.title.white {
  color: white;
}

.footer-note {
  color: var(--Dark-20, rgba(41, 45, 50, 0.2));
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'GeneralSans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.footer-note.white {
  color: white;
}

.large-label.gray {
  color: var(--Dark-20, rgba(41, 45, 50, 0.2));
}

.large-label {
  color: var(--Dark-100, #292d32);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'GeneralSans';
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 40px;
}

@media screen and (max-height: 800px) {
  .large-label {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    line-height: normal;
  }
}
