body {
  margin: 0;
  font-family: GeneralSans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --Dark-40: #292d3266;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
