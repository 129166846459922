.root-container {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f6f6;
  overflow-y: auto;
}

.root-container.black {
  background-color: #2a2d32;
  transition: all 0.5s ease;
}

.button {
  align-self: center;
}

.button.mobile {
  color: #292d32;
  font-weight: 500;
}

.button:has(+ .captcha) {
  margin-bottom: 20px;
}

.filled-input {
  color: #292d32;
}

.filled-input.Mui-focused {
  background-color: white;
}

.autocomplete {
  border-radius: 36px;
  background-color: white;
}

.autocomplete .MuiAutocomplete-input {
  padding: 1rem;
}

.autocomplete .MuiOutlinedInput-notchedOutline,
.autocomplete .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0px;
}

.autocomplete.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0px;
}

.form-root {
  max-width: 640px;
}

.form-label {
  color: var(--Dark-40, rgba(41, 45, 50, 0.4));
  font-feature-settings: 'liga' off, 'clig' off;

  font-family: 'GeneralSans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.form-label.legend {
  font-size: 10px;
}

.header-stepTitle {
  color: var(--Dark-20, rgba(41, 45, 50, 0.2));
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;

  font-family: 'GeneralSans';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  margin-top: 40px;
  max-width: 800px;
  text-align: center;
}

.button.large {
  padding: 14.4px 28.8px;
}

.button.large {
  font-size: 18px;
}

.signUp-note {
  color: var(--Mint-100, #0abab5);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'GeneralSans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}

.signUp-note.dark {
  color: var(--Dark-60, rgba(41, 45, 50, 0.6));
}

@media screen and (max-height: 800px) {
  .header-stepTitle {
    margin-top: 0px;
    font-size: 14px;
  }

  .signUp-note {
    line-height: normal;
    margin-bottom: 10px;
  }

  .button.large {
    padding: 12px 24px;
    font-size: 14px;
  }
}
